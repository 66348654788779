html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 1.2;
}

input,
select,
textarea,
button {
  font-family: inherit;
}
